import { ReportsActionTypes } from "./actionTypes";

export const INIT_STATE: any = {
  hourlySales: [],
  feesAndDiscountPrice: [],
  salesPerMenuCategories: [],
  salesPerMenuItems: [],
  salesPerSubMenuItems: [],
  salesPerPaymentTypes: [],
  loading: false,
  success: false,
};

const reports = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ReportsActionTypes.GET_HOURLY_SALES:
      return {
        ...state,
        loading: true,
      };

    case ReportsActionTypes.GET_HOURLY_SALES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        hourlySales: action.payload,
      };
    
    case ReportsActionTypes.GET_FEES_AND_DISCOUNT_PRICE:
      return {
        ...state,
        loading: true,
      };

    case ReportsActionTypes.GET_FEES_AND_DISCOUNT_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        feesAndDiscountPrice: action.payload,
      };
      
    case ReportsActionTypes.DOWNLOAD_REPORT:
      return {
        ...state,
        loading: true,
      };
    
    case ReportsActionTypes.DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ReportsActionTypes.GET_SALES_PER_MENU_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case ReportsActionTypes.GET_SALES_PER_MENU_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        salesPerMenuCategories: action.payload,
      };
    case ReportsActionTypes.GET_SALES_PER_MENU_ITEM:
      return {
        ...state,
        loading: true,
      };
    case ReportsActionTypes.GET_SALES_PER_MENU_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        salesPerMenuItems: action.payload,
      };
    case ReportsActionTypes.GET_SALES_PER_SUB_MENU_ITEM:
      return {
        ...state,
        loading: true,
      };
    case ReportsActionTypes.GET_SALES_PER_SUB_MENU_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        salesPerSubMenuItems: action.payload,
      };
    case ReportsActionTypes.GET_SALES_PER_PAYMENT_TYPES:
      return {
        ...state,
        loading: true,
      };

    case ReportsActionTypes.GET_SALES_PER_PAYMENT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        salesPerPaymentTypes: action.payload,
      };

    case ReportsActionTypes.GET_AVG_PREP_TIME_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        avgPrepTime: action.payload,
      };

    default:
      return state;
  }
};

export default reports;
