import { post, get, put, del, postFormData, getBlob } from "./api_helper";
import { convertSecondsToHMS } from "./general";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

const getAuthUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
}

const getUserRestaurantId = () => {
  const user = getAuthUser()
  if (user && user.isAdmin === 0) {
    if (user?.RestaurantIds && (JSON.parse(user?.RestaurantIds))?.length>0) {
      if ((JSON.parse(user?.RestaurantIds))?.length==1 && (JSON.parse(user?.RestaurantIds))[0]==user.RestaurantId) {
        return user.RestaurantId;
      }
      return 0
    }
    return user.RestaurantId;
  }
  return -1
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

//admin login
const loginAdminAsync = (data : any) => post(url.ADMIN_LOGIN, data)

//admins
const getAllAdminsAsync = () => get(`${url.GET_ALL_ADMINS}`)
const getAdminsByCompanyCodeAsync = (companyCode: string) => get(`${url.GET_ADMINS_BY_COMPANYCODE}/${companyCode}`)
const addAdminAsync = (admin: any) => post(url.ADD_ADMIN, admin)
const getAdminByIdAsync = (id: string) => get(`${url.GET_ADMIN_BY_ID}/${id}`)
const updateAdminByDocIdAsync = (docid: string, admin: any) =>  put(`${url.UPDATE_ADMIN_BY_DOCID}/${docid}`, admin)
const deleteAdminByDocIdAsyc = (id: string) => del(`${url.DEL_ADMIN_BY_DOCID}/${id}`)

//licenses
const getAllLicensesAsync = () => get(`${url.GET_ALL_LICENSES}`)
const addLicenseAsync = (license: any) => post(url.ADD_LICENSE, license)
const getLicenseByIdAsync = (id: string) => get(`${url.GET_LICENSE_BY_ID}/${id}`)
const updateLicenseByIdAsync = (id: string, license: any) =>  put(`${url.UPDATE_LICENSE_BY_ID}/${id}`, license)
const deleteLicenseByIdAsync = (id: string) => del(`${url.DEL_LICENSE_BY_ID}/${id}`)

//customers
const getCustomerByIdAsync = (id: string) => get(
  `${url.GET_SINGLE_CUSTOMER}/${id}`
).then(res => ({success: res.success, result: res.data}))

const getAllCustomersAsync = () => Promise.all([
  get(`${url.GET_ALL_CUSTOMERS}`),
  get(`${url.GET_ALL_RESTAURANTS}`)
]).then((result) => {
  const restaurants = result[1].result
  const customers: Array<any> = result[0].result
  let names: any = {}
  for(let i=0; i<restaurants.length; i++) {
    names[restaurants[i].id] = restaurants[i].ShopName
  }
  return {
    success: true,
    result: customers.map(customer => Object.assign({...customer, RestaurantName: names[customer.RestaurantId]}))
  }
})
const getRestaurantCustomersAsync = (id: string) => get(`${url.GET_RESTAURANT_CUSTOMERS}/${id}`).then(r => ({...r, result: r.data}))

//orders
const getAllOrdersAsync = (isWebOrderOnly: boolean, offset = 0, limit = 20, dateStart = undefined, dateEnd = undefined) => Promise.all([
  get(`${url.GET_ORDERS_PAGINATED}?pageIndex=${offset}&pageSize=${limit}${isWebOrderOnly?'&IsWebOrder=1':''}${dateStart?'&dateStart='+dateStart:''}${dateEnd?'&dateEnd='+dateEnd:''}`),
  get(`${url.GET_ALL_RESTAURANTS}`)
]).then((result) => {
  const restaurants = result[1].result
  const orders: Array<any> = result[0].result.rows
  let names: any = {}
  for(let i=0; i<restaurants.length; i++) {
    names[restaurants[i].id] = restaurants[i].ShopName
  }
  return {
    success: true,
    result: {
      orders: orders.map(order => Object.assign({...order, RestaurantName: names[order.RestaurantId]})),
      totalCount: result[0].result.count
    }
  }
})
const getRestaurantOrdersAsync = (id: string, isWebOrderOnly: boolean, offset = 0, limit = 20, dateStart = undefined, dateEnd = undefined) =>Promise.all([
  get(`${url.GET_RESTAURANT_ORDERS_WITH_PAGINATION}/${id}?pageIndex=${offset}&pageSize=${limit}${isWebOrderOnly?'&IsWebOrder=1':''}${dateStart?'&dateStart='+dateStart:''}${dateEnd?'&dateEnd='+dateEnd:''}`),
  get(`${url.GET_ALL_RESTAURANTS}`)
]).then((result) => {
  const restaurants = result[1].result
  const orders: Array<any> = result[0].result.rows
  let names: any = {}
  for(let i=0; i<restaurants.length; i++) {
    names[restaurants[i].id] = restaurants[i].ShopName
  }
  return {
    success: true,
    result: {
      orders: orders.map(order => Object.assign({...order, RestaurantName: names[order.RestaurantId]})),
      totalCount: result[0].result.count
    }
  }
})

//order details
const getOrderDetailByIdAsync = (orderId: number) => get(`${url.GET_ORDER_DETAIL_BY_ID}/${orderId}`)

//restaurants
const getAllRestaurantsAsync = () => get(`${url.GET_ALL_RESTAURANTS}`)
const getAllRestaurantsByManagerAsync = (ids: string) => get(`${url.GET_ALL_RESTAURANTS}/manager/${ids}`)
const getRestaurantByIdAsync = (id: string) => get(`${url.GET_RESTAURANT_BY_ID}/${id}`)
const updateRestaurantByIdAsync = (id: string, restaurant: any) =>  put(`${url.UPDATE_RESTAURANT_BY_DOCID}/${id}`, restaurant)
const addRestaurantAsync = (restaurant: any) => post(url.ADD_RESTAURANT, restaurant)

//migrations
const getAllMigrationsAsync = () => get(`${url.GET_ALL_MIGRATIONS}`)
const getMigrationByIdAsync = (id: string) => get(`${url.GET_MIGRATION_BY_ID}/${id}`)
const deleteMigrationByIdAsync = (id: string) => del(`${url.DELETE_MIGRATION_BY_ID}/${id}`)

//menu
const getAllMenuCategoriesAsync = () => get(`${url.GET_ALL_MENU_CATEGORIES}`)
const getRestaurantMenuCategoriesAsync = (id: string) => get(`${url.GET_RESTAURANT_MENU_CATEGORIES}/${id}`)
const getRestaurantMenuCategoriesWithItemsAsync = (id: string) => get(`${url.GET_RESTAURANT_MENU_CATEGORIES}/${id}?withItems=true`)
const getMenuCategoryByIdAsnyc = (id: string) => get(`${url.GET_MENU_CATEGORY_BY_ID}/${id}`)
const updateMenuCategoryByIdAsync = (id: string, menuCategory: any) => put(`${url.UPDATE_MENU_CATEGORY}/${id}`, menuCategory)
const addMenuCategoryAsync = (menuCategory: any) => post(`${url.ADD_MENU_CATEGORY}`, menuCategory)
const deleteMenuCategoryByIdAsync = (id: string) => del(`${url.DELETE_MENU_CATEGORY}/${id}`)

//menu images
const addMenuImageAsync = (imageData: any, restaurantId: string) => post(`${url.ADD_MENU_IMAGE}`, {image: imageData, RestaurantId: restaurantId})
const batchAddMenuImagesAsync = (imagesData: any) => post(`${url.BATCH_ADD_MENU_IMAGE}`, imagesData)
const uploadSingleImageAsync = (imageData: any, restaurantId: string) =>postFormData(`${url.BATCH_ADD_MENU_IMAGE}/${restaurantId}`, imageData,{
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})
const getRestaurantMenuImagesAsync = (id: string) => get(`${url.GET_RESTAURANT_IMAGES}/${id}`)

//menu items
const getAllMenuItemsAsync = () => get(`${url.GET_ALL_MENU_ITEMS}`)
const getCategoryMenuItemsAsync = (id: string) => get(`${url.GET_CATEGORY_MENU_ITEMS}/${id}`)
const getSupplierMenuItemsAsync = (id: string) => get(`${url.GET_SUPPLIER_MENU_ITEMS}/${id}`)
const getMenuItemByIdAsnyc = (id: string) => get(`${url.GET_MENU_ITEM_BY_ID}/${id}`)
const updateMenuItemByIdAsync = (id: string, menuItem: any) => put(`${url.UPDATE_MENU_ITEM}/${id}`, menuItem)
const addMenuItemAsync = (menuItem: any) => post(`${url.ADD_MENU_ITEM}`, menuItem)
const deleteMenuItemByIdAsync = (id: string) => del(`${url.DELETE_MENU_ITEM}/${id}`)

//sub menu items
const getAllSubMenuItemsAsync = () => get(`${url.GET_ALL_SUB_MENU_ITEMS}`)
const getMenuItemSubMenuItemsAsync = (id: string) => get(`${url.GET_MENUITEM_SUBMENUITEMS}/${id}`)
const getSubMenuItemByIdAsnyc = (id: string) => get(`${url.GET_SUB_MENU_ITEM_BY_ID}/${id}`)
const updateSubMenuItemByIdAsync = (id: string, menuItem: any) => put(`${url.UPDATE_SUB_MENU_ITEM}/${id}`, menuItem)
const addSubMenuItemAsync = (menuItem: any) => post(`${url.ADD_SUB_MENU_ITEM}`, menuItem)
const deleteSubMenuItemByIdAsync = (id: string) => del(`${url.DELETE_SUB_MENU_ITEM}/${id}`)

//menu toppings
const getAllMenuToppingsAsync = () => get(`${url.GET_ALL_MENU_TOPPINGS}`)
const getMenuToppingByIdAsnyc = (id: string) => get(`${url.GET_MENU_TOPPING_BY_ID}/${id}`)
const updateMenuToppingByIdAsync = (id: string, menuItem: any) => put(`${url.UPDATE_MENU_TOPPING}/${id}`, menuItem)
const addMenuToppingAsync = (menuItem: any) => post(`${url.ADD_MENU_TOPPING}`, menuItem)
const deleteMenuToppingByIdAsync = (id: string) => del(`${url.DELETE_MENU_TOPPING}/${id}`)

//menu dressings
const getAllMenuDressingsAsync = () => get(`${url.GET_ALL_MENU_DRESSINGS}`)
const getMenuDressingByIdAsnyc = (id: string) => get(`${url.GET_MENU_DRESSING_BY_ID}/${id}`)
const updateMenuDressingByIdAsync = (id: string, menuItem: any) => put(`${url.UPDATE_MENU_DRESSING}/${id}`, menuItem)
const addMenuDressingAsync = (menuItem: any) => post(`${url.ADD_MENU_DRESSING}`, menuItem)
const deleteMenuDressingByIdAsync = (id: string) => del(`${url.DELETE_MENU_DRESSING}/${id}`)

//menu special instructions
const getAllMenuInstructionsAsync = () => get(`${url.GET_ALL_MENU_INSTRUCTIONS}`)
const getMenuInstructionByIdAsnyc = (id: string) => get(`${url.GET_MENU_INSTRUCTION_BY_ID}/${id}`)
const updateMenuInstructionByIdAsync = (id: string, menuItem: any) => put(`${url.UPDATE_MENU_INSTRUCTION}/${id}`, menuItem)
const addMenuInstructionAsync = (menuItem: any) => post(`${url.ADD_MENU_INSTRUCTION}`, menuItem)
const deleteMenuInstructionByIdAsync = (id: string) => del(`${url.DELETE_MENU_INSTRUCTION}/${id}`)

//reports
const getHourlySalesByRestaurantIdAsync = (restaurantId: string, dateFrom: string, dateTo: string) => get(`${url.GET_HOURLY_SALES}/${restaurantId}?dateFrom=${dateFrom}&dateTo=${dateTo}`)
const getFeesAndDiscountPriceAsync = (restaurantId: string, dateFrom: string, dateTo: string) => get(`${url.GET_FEES_AND_DISCOUNT_PRICE}/${restaurantId}?dateFrom=${dateFrom}&dateTo=${dateTo}`)
const getSalesPerMenuCategoriesAsync = (restaurantId: string, dateFrom: string, dateTo: string) => get(`${url.GET_SALES_PER_MENU_CATEGORIES}/${restaurantId}?dateFrom=${dateFrom}&dateTo=${dateTo}`)
const getSalesPerPaymentTypesAsync = (restaurantId: string, dateFrom: string, dateTo: string) => get(`${url.GET_SALES_PER_PAYMENT_TYPES}/${restaurantId}?dateFrom=${dateFrom}&dateTo=${dateTo}`)
const getSalesPerMenuItemsAsync = (restaurantId: string, categoryId: string, dateFrom: string, dateTo: string) => get(`${url.GET_SALES_PER_MENU_ITEMS}/${restaurantId}/${categoryId}?dateFrom=${dateFrom}&dateTo=${dateTo}`)
const getSalesPerSubMenuItemsAsync = (restaurantId: string, menuItemId: string, dateFrom: string, dateTo: string) => get(`${url.GET_SALES_PER_SUB_MENU_ITEMS}/${restaurantId}/${menuItemId}?dateFrom=${dateFrom}&dateTo=${dateTo}`)
const getAvgPrepTimeAsync = (restaurantId: string, dateFrom: string, dateTo: string) => get(`${url.GET_AVG_PREP_TIME}/${restaurantId}?dateFrom=${dateFrom}&dateTo=${dateTo}`)
const downloadReportAsync = (restaurantId: string, dateFrom: string, dateTo: string) => getBlob(`${url.DOWNLOAD_REPORT}/${restaurantId}?dateFrom=${dateFrom}&dateTo=${dateTo}`)

//employees
const getAllEmployeesAsync = () => get(`${url.GET_ALL_EMPLOYEES}`)
const getRestaurantEmployeesAsync = (id: string) => get(`${url.GET_RESTAURANT_EMPLOYEES}/${id}?drivers=0`)
const getRestaurantDriversAsync = (id: string) => get(`${url.GET_RESTAURANT_EMPLOYEES}/${id}?drivers=1`)
const getEmployeeByIdAsync = (id: string) => get(`${url.GET_EMPLOYEE_BY_ID}/${id}`)
const updateEmployeeByIdAsync = (id: string, employeeData: any) => put(`${url.UPDATE_EMPLOYEE}/${id}`, employeeData)
const addEmployeeAsync = (employeeData: any) => post(`${url.ADD_EMPLOYEE}`, employeeData)
const deleteEmployeeByIdAsync = (id: string) => del(`${url.DELETE_EMPLOYEE}/${id}`)

//printers
const getAllPrintersAsync = (id: string) => get(`${url.GET_RESTAURANT_PRINTERS}/${id}`)
const getPrinterByIdAsync = (id: string) => get(`${url.GET_PRINTER_BY_ID}/${id}`)
const updatePrinterByIdAsync = (id: string, menuItem: any) => put(`${url.UPDATE_PRINTER_BY_ID}/${id}`, menuItem)
const addPrinterAsync = (menuItem: any) => post(`${url.ADD_PRINTER}`, menuItem)
const deletePrinterByIdAsync = (id: string) => del(`${url.DELETE_PRINTER_BY_ID}/${id}`)

//card terminals
const getAllCardTerminalsAsync = (id: string) => get(`${url.GET_RESTAURANT_CARD_TERMINALS}/${id}`)
const getCardTerminalByIdAsync = (id: string) => get(`${url.GET_CARD_TERMINAL_BY_ID}/${id}`)
const updateCardTerminalByIdAsync = (id: string, menuItem: any) => put(`${url.UPDATE_CARD_TERMINAL_BY_ID}/${id}`, menuItem)
const addCardTerminalAsync = (menuItem: any) => post(`${url.ADD_CARD_TERMINAL}`, menuItem)
const deleteCardTerminalByIdAsync = (id: string) => del(`${url.DELETE_CARD_TERMINAL_BY_ID}/${id}`)

//gift cards
const getAllGiftCardsAsync = (id: string) => get(`${url.GET_RESTAURANT_GIFT_CARDS}/${id}`)
const getGiftCardByIdAsync = (id: string) => get(`${url.GET_GIFT_CARD_BY_ID}/${id}`)
const updateGiftCardByIdAsync = (id: string, menuItem: any) => put(`${url.UPDATE_GIFT_CARD_BY_ID}/${id}`, menuItem)
const addGiftCardAsync = (menuItem: any) => post(`${url.ADD_GIFT_CARD}`, menuItem)
const deleteGiftCardByIdAsync = (id: string) => del(`${url.DELETE_GIFT_CARD_BY_ID}/${id}`)

//kitchen display screens
const getAllKDScreensAsync = () => get(`${url.GET_ALL_KD_SCREENS}`)
const getRestaurantKDScreensAsync = (id: string) => get(`${url.GET_RESTAURANT_KD_SCREENS}/${id}`)
const getKDScreenByIdAsnyc = (id: string) => get(`${url.GET_KD_SCREEN_BY_ID}/${id}`)
const updateKDScreenByIdAsync = (id: string, menuCategory: any) => put(`${url.UPDATE_KD_SCREEN}/${id}`, menuCategory)
const addKDScreenAsync = (menuCategory: any) => post(`${url.ADD_KD_SCREEN}`, menuCategory)
const deleteKDScreenByIdAsync = (id: string) => del(`${url.DELETE_KD_SCREEN}/${id}`)

//stats
const getAllStatsAsync = (restaurantId: number) => {
  const urls = [
    `${url.GET_TOTAL_SALES_TODAY}`,
    `${url.GET_TOTAL_COUNTS_TODAY}`,

    `${url.GET_COUNTS_CASH}`,
    `${url.GET_COUNTS_CARD}`,

    `${url.GET_COUNTS_DELIVERY}`,
    `${url.GET_COUNTS_TAKEAWAY}`,
    `${url.GET_COUNTS_COLLECTION}`,
    `${url.GET_COUNTS_EATIN}`,

    `${url.GET_TOTAL_WEBSITES_TODAY}`,
    `${url.GET_RESTAURANT_CUSTOMERS}`,
    `${url.GET_AVG_PREP_TIME}`
  ]

  return Promise.all(urls.map(async url => {
    try {
      return await get(url + "/" + restaurantId)
    } catch(err) {
      return {
        success: false,
        total: 0,
        totalCount: 0
      }
    }
  })).then(result => Object.assign({
    success: true, 
    result: {
      numberOfCustomers: result[9].data.length,
      totalSale: result[0].total.toFixed(2),
      numberOfTodayOrders: result[1].totalCount,
      cashOrderProcessed: result[2].totalCount,
      cardOrderProcessed: result[3].totalCount,
      avgPrepTime: convertSecondsToHMS(Math.floor(result[10].result.avgPrepTime)),
      progs: {
        delivery: result[4].totalCount,
        takeaway: result[5].totalCount,
        collection: result[6].totalCount,
        eatin: result[7].totalCount,
        website: result[8].totalCount
      }
    }
  }))
}

//suppliers
const getAllSuppliersAsync = (id: string) => get(`${url.GET_RESTAURANT_SUPPLIERS}/${id}`)
const getSupplierByIdAsync = (id: string) => get(`${url.GET_SUPPLIER_BY_ID}/${id}`)
const updateSupplierByIdAsync = (id: string, menuItem: any) => put(`${url.UPDATE_SUPPLIER_BY_ID}/${id}`, menuItem)
const addSupplierAsync = (menuItem: any) => post(`${url.ADD_SUPPLIER}`, menuItem)
const deleteSupplierByIdAsync = (id: string) => del(`${url.DELETE_SUPPLIER_BY_ID}/${id}`)

//purchase orders
const getAllPurchaseOrdersAsync = (id: string) => get(`${url.GET_RESTAURANT_PURCHASE_ORDERS}/${id}`)
const getPurchaseOrderByIdAsync = (id: string) => get(`${url.GET_PURCHASE_ORDER_BY_ID}/${id}`)
const updatePurchaseOrderByIdAsync = (id: string, order: any) => put(`${url.UPDATE_PURCHASE_ORDER_BY_ID}/${id}`, order)
const addPurchaseOrderAsync = (order: any) => post(`${url.ADD_PURCHASE_ORDER}`, order)
const deletePurchaseOrderByIdAsync = (id: string) => del(`${url.DELETE_PURCHASE_ORDER_BY_ID}/${id}`)

//bookings
const getRestaurantBookingsAsync = (id: string) => get(`${url.GET_RESTAURANT_BOOKINGS}/${id}`)
const storeBookingAsync = (booking: any) => post(`${url.STORE_BOOKING}`, booking)
const updateBookingAsync = (id: string, booking: any) => put(`${url.UPDATE_BOOKING}/${id}`, booking)
const getBookingByIdAsync = (id: string) => get(`${url.GET_BOOKING_BY_ID}/${id}`)
const deleteBookingByIdAsync = (id: string) => del(`${url.DELETE_BOOKING_BY_ID}/${id}`)

//vatRates
const getAllVatRatesAsync = (restaurantId: any) => get(`${url.GET_ALL_VAT_RATES}/${restaurantId}`)

export {
  getLoggedInUser,
  getAuthUser,
  getUserRestaurantId,
  isUserAuthenticated,
  loginAdminAsync,
  //admin
  getAllAdminsAsync,
  getAdminsByCompanyCodeAsync,
  addAdminAsync,
  getAdminByIdAsync,
  updateAdminByDocIdAsync,
  deleteAdminByDocIdAsyc,

  //license
  getAllLicensesAsync,
  addLicenseAsync,
  getLicenseByIdAsync,
  updateLicenseByIdAsync,
  deleteLicenseByIdAsync,

  //customers
  getAllCustomersAsync,
  getCustomerByIdAsync,
  getRestaurantCustomersAsync,

  //restaurants
  getAllRestaurantsAsync,
  getAllRestaurantsByManagerAsync,
  getRestaurantByIdAsync,
  updateRestaurantByIdAsync,
  addRestaurantAsync,
  //orders
  getAllOrdersAsync,
  getRestaurantOrdersAsync,
  //order details
  getOrderDetailByIdAsync,
  
  //stats
  getAllStatsAsync,

  //menus
  //categories
  getAllMenuCategoriesAsync,
  getRestaurantMenuCategoriesAsync,
  getRestaurantMenuCategoriesWithItemsAsync,
  getMenuCategoryByIdAsnyc,
  updateMenuCategoryByIdAsync,
  addMenuCategoryAsync,
  deleteMenuCategoryByIdAsync,
  //items
  getAllMenuItemsAsync,
  getCategoryMenuItemsAsync,
  getSupplierMenuItemsAsync,
  getMenuItemByIdAsnyc,
  addMenuItemAsync,
  updateMenuItemByIdAsync,
  deleteMenuItemByIdAsync,
  //SubMenuItems
  getAllSubMenuItemsAsync,
  getMenuItemSubMenuItemsAsync,
  getSubMenuItemByIdAsnyc,
  updateSubMenuItemByIdAsync,
  addSubMenuItemAsync,
  deleteSubMenuItemByIdAsync,
  //Menu toppings
  getAllMenuToppingsAsync,
  getMenuToppingByIdAsnyc,
  updateMenuToppingByIdAsync,
  addMenuToppingAsync,
  deleteMenuToppingByIdAsync,
  //Menu dressings
  getAllMenuDressingsAsync,
  getMenuDressingByIdAsnyc,
  updateMenuDressingByIdAsync,
  addMenuDressingAsync,
  deleteMenuDressingByIdAsync,
  //Menu special instructions
  getAllMenuInstructionsAsync,
  getMenuInstructionByIdAsnyc,
  updateMenuInstructionByIdAsync,
  addMenuInstructionAsync,
  deleteMenuInstructionByIdAsync,
  //Reports
  getHourlySalesByRestaurantIdAsync,
  getFeesAndDiscountPriceAsync,
  getSalesPerMenuCategoriesAsync,
  getSalesPerPaymentTypesAsync,
  getSalesPerMenuItemsAsync,
  getSalesPerSubMenuItemsAsync,
  getAvgPrepTimeAsync,
  downloadReportAsync,
  //Employees
  getAllEmployeesAsync,
  getRestaurantEmployeesAsync,
  getRestaurantDriversAsync,
  getEmployeeByIdAsync,
  updateEmployeeByIdAsync,
  addEmployeeAsync,
  deleteEmployeeByIdAsync,
  //Printers
  getAllPrintersAsync,
  getPrinterByIdAsync,
  updatePrinterByIdAsync,
  deletePrinterByIdAsync,
  addPrinterAsync,
  //CardTerminals
  getAllCardTerminalsAsync,
  getCardTerminalByIdAsync,
  updateCardTerminalByIdAsync,
  deleteCardTerminalByIdAsync,
  addCardTerminalAsync,
  //GiftCards
  getAllGiftCardsAsync,
  getGiftCardByIdAsync,
  updateGiftCardByIdAsync,
  deleteGiftCardByIdAsync,
  addGiftCardAsync,
  //KitchenDisplayScreens
  getAllKDScreensAsync,
  getRestaurantKDScreensAsync,
  getKDScreenByIdAsnyc,
  updateKDScreenByIdAsync,
  addKDScreenAsync,
  deleteKDScreenByIdAsync,
  //Suppliers
  getAllSuppliersAsync,
  getSupplierByIdAsync,
  updateSupplierByIdAsync,
  deleteSupplierByIdAsync,
  addSupplierAsync,
  //PurchaseOrders
  getAllPurchaseOrdersAsync,
  getPurchaseOrderByIdAsync,
  updatePurchaseOrderByIdAsync,
  deletePurchaseOrderByIdAsync,
  addPurchaseOrderAsync,
  //MenuImages
  addMenuImageAsync,
  batchAddMenuImagesAsync,
  uploadSingleImageAsync,
  getRestaurantMenuImagesAsync,
  //Migrations
  getAllMigrationsAsync,
  getMigrationByIdAsync,
  deleteMigrationByIdAsync,
  //Bookings
  getRestaurantBookingsAsync,
  storeBookingAsync,
  updateBookingAsync,
  getBookingByIdAsync,
  deleteBookingByIdAsync,
  //vat
  getAllVatRatesAsync
}