import { ReportsActionTypes } from "./actionTypes";

//get hourly sales by restaurant id
export const getHourlySales = (restaurantId: string, dateFrom: string, dateTo: string) => ({
  type: ReportsActionTypes.GET_HOURLY_SALES,
  payload: {
    restaurantId,
    dateFrom,
    dateTo
  }
});

//get hourly sales by restaurant id
export const getFeesAndDiscountPrice = (restaurantId: string, dateFrom: string, dateTo: string) => ({
  type: ReportsActionTypes.GET_FEES_AND_DISCOUNT_PRICE,
  payload: {
    restaurantId,
    dateFrom,
    dateTo
  }
});

export const downloadReport = (restaurantId: string, dateFrom: string, dateTo: string) => ({
  type: ReportsActionTypes.DOWNLOAD_REPORT,
  payload: {
    restaurantId,
    dateFrom,
    dateTo
  }
});

export const downloadReportSuccess = () => ({
  type: ReportsActionTypes.DOWNLOAD_REPORT_SUCCESS,
});

export const getHourlySalesSuccess = (hourlySales: any) => ({
  type: ReportsActionTypes.GET_HOURLY_SALES_SUCCESS,
  payload: hourlySales,
});

export const getFeesAndDiscountPriceSuccess = (feesAndDiscountPrice: any) => ({
  type: ReportsActionTypes.GET_FEES_AND_DISCOUNT_PRICE_SUCCESS,
  payload: feesAndDiscountPrice,
});

//get sales per menu categories
export const getSalesPerMenuCategories = (restaurantId: string, dateFrom: string, dateTo: string) => ({
  type: ReportsActionTypes.GET_SALES_PER_MENU_CATEGORY,
  payload: {
    restaurantId,
    dateFrom,
    dateTo
  }
});

export const getSalesPerMenuCategoriesSuccess = (salesPerMenuCategories: any) => ({
  type: ReportsActionTypes.GET_SALES_PER_MENU_CATEGORY_SUCCESS,
  payload: salesPerMenuCategories,
});

//get sales per menu items
export const getSalesPerMenuItems = (restaurantId: string, categoryId: string, dateFrom: string, dateTo: string) => ({
  type: ReportsActionTypes.GET_SALES_PER_MENU_ITEM,
  payload: {
    restaurantId,
    categoryId,
    dateFrom,
    dateTo
  }
});

export const getSalesPerMenuItemsSuccess = (salesPerMenuItems: any) => ({
  type: ReportsActionTypes.GET_SALES_PER_MENU_ITEM_SUCCESS,
  payload: salesPerMenuItems,
});

//get sales per menu items
export const getSalesPerSubMenuItems = (restaurantId: string, menuItemId: string, dateFrom: string, dateTo: string) => ({
  type: ReportsActionTypes.GET_SALES_PER_SUB_MENU_ITEM,
  payload: {
    restaurantId,
    menuItemId,
    dateFrom,
    dateTo
  }
});

export const getSalesPerSubMenuItemsSuccess = (salesPerSubMenuItems: any) => ({
  type: ReportsActionTypes.GET_SALES_PER_SUB_MENU_ITEM_SUCCESS,
  payload: salesPerSubMenuItems,
});

//get sales per payment types
export const getSalesPerPaymentTypes = (restaurantId: string, dateFrom: string, dateTo: string) => ({
  type: ReportsActionTypes.GET_SALES_PER_PAYMENT_TYPES,
  payload: {
    restaurantId,
    dateFrom,
    dateTo
  }
});

export const getSalesPerPaymentTypesSuccess = (salesPerPaymentTypes: any) => ({
  type: ReportsActionTypes.GET_SALES_PER_PAYMENT_TYPES_SUCCESS,
  payload: salesPerPaymentTypes,
});

export const getAvgPrepTime = (restaurantId: string, dateFrom: string, dateTo: string) => ({
  type: ReportsActionTypes.GET_AVG_PREP_TIME,
  payload: {
    restaurantId,
    dateFrom,
    dateTo
  }
});

export const getAvgPrepTimeSuccess = (avgPrepTime: number) => ({
  type: ReportsActionTypes.GET_AVG_PREP_TIME_SUCCESS,
  payload: avgPrepTime
})

export const reportsApiCallError = (error: any) => ({
  type: ReportsActionTypes.API_RETURN_ERROR,
  payload: error
})
