export enum ReportsActionTypes {
  GET_HOURLY_SALES = "GET_HOURLY_SALES",
  GET_HOURLY_SALES_SUCCESS = 'GET_HOURLY_SALES_SUCCESS',
  GET_SALES_PER_MENU_CATEGORY = "GET_SALES_PER_MENU_CATEGORY",
  GET_SALES_PER_MENU_CATEGORY_SUCCESS = 'GET_SALES_PER_MENU_CATEGORY_SUCCESS',
  GET_SALES_PER_MENU_ITEM = "GET_SALES_PER_MENU_ITEM",
  GET_SALES_PER_MENU_ITEM_SUCCESS = 'GET_SALES_PER_MENU_ITEM_SUCCESS',
  GET_SALES_PER_SUB_MENU_ITEM = "GET_SALES_PER_SUB_MENU_ITEM",
  GET_SALES_PER_SUB_MENU_ITEM_SUCCESS = 'GET_SALES_PER_SUB_MENU_ITEM_SUCCESS',
  GET_SALES_PER_PAYMENT_TYPES = 'GET_SALES_PER_PAYMENT_TYPES',
  GET_SALES_PER_PAYMENT_TYPES_SUCCESS = 'GET_SALES_PER_PAYMENT_TYPES_SUCCESS',
  GET_AVG_PREP_TIME = 'GET_AVG_PREP_TIME',
  GET_AVG_PREP_TIME_SUCCESS = 'GET_AVG_PREP_TIME_SUCCESS',
  DOWNLOAD_REPORT = 'DOWNLOAD_REPORT',
  DOWNLOAD_REPORT_SUCCESS = 'DOWNLOAD_REPORT_SUCCESS',
  API_RETURN_ERROR = 'API_RETURN_ERROR',
  GET_FEES_AND_DISCOUNT_PRICE = 'GET_FEES_AND_DISCOUNT_PRICE',
  GET_FEES_AND_DISCOUNT_PRICE_SUCCESS = 'GET_FEES_AND_DISCOUNT_PRICE_SUCCESS'
}
